<template>
  <div class="store_category van-hairline--top">
    <aside>
      <van-collapse v-model="activeName" accordion @change="select">
        <van-collapse-item
          :title="list.categoryName"
          :name="list.categoryId"
          v-for="list in category"
          :key="list.categoryId"
          :class="list.isSelect ? 'pitch_on' : ''"
        >
          <p
            v-for="item in list.subItemList"
            :key="item.categoryId"
            @click="selectItem(item)"
            :class="item.isSelect ? 'pitch_on' : ''"
          >
            {{ item.categoryName }}
          </p>
        </van-collapse-item>
      </van-collapse>
      <!-- <ul class="stair">
        <li
          class="stair_cont"
          v-for="(list, index) in category"
          :key="list.categoryId"
          @click="select(index, '', list.categoryId)"
        >
          <p :class="list.isSelect ? 'pitch_on' : ''">
            {{ list.categoryName }}
          </p>
          <ul
            class="second"
            v-show="list.isSelect"
            :ref="`ul_${list.categoryId}`"
          >
            <li
              class="second_cont"
              v-for="(item, i) in list.subItemList"
              :key="item.categoryId"
              @click="select(index, i)"
            >
              {{ item.categoryName }}
            </li>
          </ul>
        </li>
      </ul> -->
    </aside>
    <main>
      <div class="sort">
        <p @click="switchover(0)" :class="index === 0 ? 'pitch_on' : ''">
          综合排序
        </p>
        <p @click="switchover(1)" :class="index === 1 ? 'pitch_on' : ''">
          销量
          <span>
            <svg-icon
              icon-class="home_more_icon1"
              :class="index === 1 && order ? 'pitch_on' : ''"
            />
            <svg-icon
              icon-class="home_more_icon1"
              :class="index === 1 && !order ? 'pitch_on' : ''"
            />
          </span>
        </p>
        <p @click="switchover(2)" :class="index === 2 ? 'pitch_on' : ''">
          价格
          <span>
            <svg-icon
              icon-class="home_more_icon1"
              :class="index === 2 && order ? 'pitch_on' : ''"
            />
            <svg-icon
              icon-class="home_more_icon1"
              :class="index === 2 && !order ? 'pitch_on' : ''"
            />
          </span>
        </p>
      </div>
      <div class="goods_box">
        <div
          class="goods"
          v-for="(list, i) in goods"
          :key="i"
          @click="goDetails(list)"
        >
          <img :src="list.filePath" alt="" />
          <div class="details">
            <h3>{{ list.commodityName }}</h3>

            <p class="price">
              <span>￥</span>
              <b style="margin-right: 2px" v-if="list.goodsStatus === 1">{{
                list.entity.price.toFixed(2)
              }}</b>
              <b style="margin-right: 2px" v-else-if="list.goodsStatus === 2">{{
                list.querySecKillCommodityListRsp.retailSeckillPrice.toFixed(2)
              }}</b>
              <b
                style="margin-right: 2px"
                v-else-if="userType === 'PURCHASE'"
                >{{ list | tradePrice }}</b
              ><b style="margin-right: 2px" v-else>{{ list | retailPrice }}</b
              >/{{ list.prickle }}
            </p>

            <p class="address">
              {{ list.province | city }}{{ list.city | city }}
            </p>
          </div>
        </div>

        <van-loading size="30" vertical v-show="isLoading"
          >加载中...</van-loading
        >
        <van-empty
          description="暂无商品"
          v-show="!isLoading && !goods.length"
        />
      </div>
    </main>
  </div>
</template>

<script>
import { preferential } from "@/utils/validate";
import { mapState } from "vuex";
export default {
  name: "store-category",
  data() {
    return {
      category: [],
      activeName: "",
      arr: [],
      goods: [],
      isLoading: false,
      order: true,
      index: 0,
    };
  },
  watch: {
    $route: {
      handler(val) {
        if (val.query.like) {
          this.activeName = "";
          this.category.forEach((a) => {
            a.isSelect = false;
            if (a.subItemList) {
              a.subItemList.forEach((b) => {
                b.isSelect = false;
              });
            }
          });
          this.getSearchGoods();
        } else {
          if (this.category.length) {
            this.category.forEach((a, i) => {
              if (!i && !this.$route.query.like) {
                a.isSelect = true;
                this.activeName = a.categoryId;
              }
            });
            this.getGoods();
          }
        }
      },
      immediate: true,
    },
  },
  filters: {
    city(city) {
      return city.split("省")[0].split("市")[0];
    },
    tradePrice(value) {
      return preferential(value, "tradePrice").price;
    },
    retailPrice(value) {
      return preferential(value, "retailPrice").price;
    },
  },
  computed: {
    ...mapState("user", ["userType", "tzInfo", "isLogin", "userInfo"]),
  },
  created() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const data = await this.$API.store.getStoreCategory({
        object: {
          storeId: this.$route.query.storeId,
        },
      });
      data.data.data.categoriesCatalog.forEach((a, i) => {
        // this.$set(a, "isSelect", false);
        if (!i && !this.$route.query.like) {
          a.isSelect = true;
          this.activeName = a.categoryId;
        } else {
          a.isSelect = false;
        }
        if (a.subItemList) {
          a.subItemList.forEach((b) => {
            // this.$set(b, "isSelect", false);
            b.isSelect = false;
          });
        }
      });
      this.category = data.data.data.categoriesCatalog;
      if (this.activeName) this.getGoods();
      // console.log(this.category);
    },
    select(activeNames) {
      this.order = true;
      this.index = 0;
      if (this.$route.query.like) {
        this.$router.replace(
          `StoreCategory?storeId=${this.$route.query.storeId}`
        );
      }
      this.category.forEach((item) => {
        item.isSelect = false;
        if (item.categoryId === activeNames) item.isSelect = true;
        if (item.subItemList) {
          item.subItemList.forEach((i) => {
            i.isSelect = false;
          });
        }
      });
      this.getGoods();
    },
    selectItem(val) {
      this.order = true;
      this.index = 0;
      if (this.$route.query.like) {
        this.$router.replace(
          `StoreCategory?storeId=${this.$route.query.storeId}`
        );
      }
      this.category.forEach((list) => {
        list.isSelect = false;
        if (list.subItemList) {
          list.subItemList.forEach((item) => {
            item.isSelect = false;
            if (item.categoryId === val.categoryId) item.isSelect = true;
          });
        }
      });
      this.getGoods();
    },
    async getGoods() {
      this.goods = [];
      this.isLoading = true;

      this.arr = [];
      const _ = this;
      function pushCategoryId(arr) {
        arr.forEach((item) => {
          _.arr.push(item.categoryId);
          if (item.subItemList) pushCategoryId(item.subItemList);
        });
      }
      this.category.forEach((list) => {
        if (list.isSelect) {
          this.arr.push(list.categoryId);
          if (list.subItemList) {
            pushCategoryId(list.subItemList);
          }
        } else if (list.subItemList) {
          list.subItemList.forEach((item) => {
            if (item.isSelect) {
              this.arr.push(item.categoryId);
              if (item.subItemList) pushCategoryId(item.subItemList);
            }
          });
        }
      });
      await this.$API.homePage
        .CommList({
          object: {
            brandId: "",
            enCategoryId: this.arr,
            location: "",
            order: this.order ? "desc" : "asc",
            productionPlace: "",
            queryCriteria: 0,
            sortColumn: this.sortColumn,
            memberId: this.isLogin ? this.userInfo.id : undefined,
            userId: this.tzInfo ? this.tzInfo.id : "",
          },
          pageNum: 0,
          pageSize: 0,
        })
        .then((res) => {
          this.isLoading = false;
          this.goods = res.data.data.map((item) => {
            return {
              ...item,
              filePath: item.filePath.split(",")[0].endsWith(".mp4")
                ? item.filePath.split(",")[1]
                : item.filePath.split(",")[0],
              goodsStatus: 0,
              // quantity: item.orderCartListItems.length
              //   ? item.orderCartListItems[0].quantity
              //   : 0,
            };
          });
          this.goods.forEach((item) => {
            if (
              item.newUser &&
              item.entity &&
              item.querySecKillCommodityListRsp
            ) {
              if (
                item.entity.price <=
                item.querySecKillCommodityListRsp.retailSeckillPrice
              ) {
                item.goodsStatus = 1;
              } else {
                item.goodsStatus = 2;
              }
            } else if (item.newUser && item.entity) {
              item.goodsStatus = 1;
            } else if (item.querySecKillCommodityListRsp) {
              item.goodsStatus = 2;
            }
          });
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    async getSearchGoods() {
      this.goods = [];

      this.isLoading = true;
      await this.$API.store
        .storeHomePage({
          object: {
            like: this.$route.query.like,
            location: "",
            order: this.order ? "desc" : "asc",
            sortColumn: this.sortColumn,
            storeId: this.$route.query.storeId,
            userId: this.tzInfo ? this.tzInfo.id : "",
          },
          pageNum: 0,
          pageSize: 0,
        })
        .then((res) => {
          this.isLoading = false;
          this.goods = res.data.data.storeHomePageItems.map((a) => {
            return {
              ...a,
              newUser: res.data.data.newUser,
              entity: a.storeTastingActivityInfoEntity,
              enterpriseId: res.data.data.enterpriseId,
              filePath: a.filePath.split(",")[0].endsWith(".mp4")
                ? a.filePath.split(",")[1]
                : a.filePath.split(",")[0],
            };
          });
          this.goods.forEach((item) => {
            if (
              item.newUser &&
              item.entity &&
              item.querySecKillCommodityListRsp
            ) {
              if (
                item.entity.price <=
                item.querySecKillCommodityListRsp.retailSeckillPrice
              ) {
                item.goodsStatus = 1;
              } else {
                item.goodsStatus = 2;
              }
            } else if (item.newUser && item.entity) {
              item.goodsStatus = 1;
            } else if (item.querySecKillCommodityListRsp) {
              item.goodsStatus = 2;
            }
          });
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    goDetails(val) {
      this.$router.push({
        name: "goods-details",
        query: {
          barcode: val.barcode,
          enterpriseId: val.enterpriseId,
        },
      });
    },

    switchover(i) {
      if (this.index === i && i === 0) return;
      if (this.index != i) this.order = true;
      this.index = i;
      switch (i) {
        case 0:
          this.sortColumn = "";
          break;
        case 1:
          this.order = !this.order;
          break;
        case 2:
          this.order = !this.order;
          this.sortColumn = "retailPrice";

          break;
        default:
          break;
      }
      if (this.index === 1) return;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        if (this.activeName) {
          this.getGoods();
        } else {
          this.getSearchGoods();
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.store_category {
  position: absolute;
  top: 200px;
  width: 100%;
  bottom: 0;
  aside {
    position: absolute;
    top: 0;
    left: 0;
    width: 90px;
    bottom: 0;
    background: #f5f5f5;
    overflow-y: scroll;
    .stair {
      .stair_cont {
        > p {
          color: #666666;
          text-align: center;
          height: 50px;
          line-height: 50px;
          padding: 0 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          .svg-icon {
            position: absolute;
            bottom: 0;
            font-size: 10px;
            left: 50%;
            bottom: 8px;
            transform: translate(-50%) rotate(90deg);
          }
        }
        > p.pitch_on {
          color: #009a62;
          font-size: 16px;
          line-height: 42px;
          font-weight: 600;
          background: #fff;
        }
        .second_cont {
          line-height: 40px;
          color: #333333;
          background: #fff;
          text-align: center;
        }
      }
    }
    ::v-deep .van-collapse {
      .van-collapse-item {
        > .van-cell {
          padding: 10px;
          flex-direction: column;
          background: #f5f5f5;

          .van-cell__title {
            text-align: center;
            color: #333333;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // white-space: nowrap;
          }
          i {
            display: none;
            margin: 0;
            text-align: center;
            color: #333333;
            font-size: 10px;
            height: 14px;
            line-height: normal;
            font-weight: 600;
          }
          i::before {
            transform: rotate(90deg);
          }
        }
        > .van-cell ~ .van-collapse-item__wrapper {
          background: #f5f5f5;
        }
        .van-collapse-item__wrapper {
          .van-collapse-item__content {
            background: none;
            padding: 8px;
            p {
              margin-bottom: 8px;
              text-align: center;
            }
            p:last-child {
              margin: 0;
            }
            .pitch_on {
              color: #009a62;
              font-weight: bold;
            }
          }
        }
        .van-collapse-item__title--expanded {
          background: #fff;

          .van-cell__title {
            font-size: 16px;
          }
          i {
            display: block;
          }
        }
        .van-collapse-item__title--expanded ~ .van-collapse-item__wrapper {
          background: #fff;
        }
        .van-collapse-item__title::after {
          display: none;
        }
      }
      .van-collapse-item--border::after {
        display: none;
      }
      .pitch_on {
        .van-cell__title {
          color: #009a62 !important;
          font-weight: 600;
        }
        i {
          color: #009a62 !important;
        }
      }
    }
  }
  main {
    position: absolute;
    top: 0;
    left: 90px;
    right: 0;
    bottom: 0;
    background: #fff;
    .sort {
      display: flex;
      justify-content: flex-end;
      padding: 8px 16px;
      p {
        display: flex;
        margin-left: 28px;
        color: #666666;
        span {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 4px;
          .svg-icon {
            font-size: 7px;
            font-weight: bold;
            color: #666666;
          }
          .svg-icon:nth-child(1) {
            transform: rotate(-90deg);
          }
          .svg-icon:nth-child(2) {
            transform: rotate(90deg);
          }
          .pitch_on {
            color: #009a4d;
          }
        }
      }
      .pitch_on {
        color: #009a4d;
      }
    }
    .goods_box {
      overflow-y: scroll;
      position: absolute;
      top: 35px;
      left: 0;
      right: 0;
      bottom: 0;
      .van-loading {
        height: 100%;
        justify-content: center;
      }
      ::v-deep .van-pull-refresh {
        min-height: 100%;
      }
      .goods {
        padding: 14px 12px;
        display: flex;
        img {
          width: 100px;
          height: 100px;
          display: block;
          margin-right: 15px;
        }
        .details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          h3 {
            font-size: 16px;
            line-height: 23px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .price {
            margin: 4px 0;
            line-height: 23px;
            font-size: 14px;
            color: #fb4e10;
            span {
              font-size: 12px;
              font-style: normal;
              display: inline-block;
              transform: scale(0.8);
              -webkit-transform: scale(0.8);
              -moz-transform: scaleY(0.8);
              position: relative;
              left: 2px;
              top: 1px;
            }
          }

          .address {
            font-size: 12px;
            color: #999999;
            // width: 48px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .store {
            font-size: 12px;
            line-height: 16px;
            color: #666666;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}
</style>
